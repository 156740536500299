<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card :loading="preloader">
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text v-if="!preloader" class="py-4">
        <v-form id="form" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-alert type="warning" class="my-2" outlined>
              ACHTUNG: Admins mit Typ "FACEFORCE" sind nur für
              FACEFORCE-Mitarbeiter vorbehalten. Admin mit Typ "FACEFORCE"
              nutzen ausschließlich die Zugangsdaten vom Manage-System und haben
              Zugriff auf alle Kunden.
            </v-alert>

            <v-alert type="info" class="my-2" outlined>
              Kunden bekommen immer den Typ "Admin" zugewiesen.
            </v-alert>

            <template v-if="job === 'create'">
              <v-col cols="12">
                <v-select
                  class="align-self-center"
                  v-model="type"
                  :items="typeItems"
                  item-text="name"
                  item-value="value"
                  label="Typ"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>

              <v-col v-if="type !== 'faceforce'" cols="12">
                <v-select
                  class="align-self-center"
                  v-model="sex"
                  :items="sexItems"
                  item-text="name"
                  item-value="value"
                  label="Geschlecht"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col v-if="type !== 'faceforce'" cols="12" md="6">
                <v-text-field
                  label="Vorname"
                  v-model="firstname"
                  type="text"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col v-if="type !== 'faceforce'" cols="12" md="6">
                <v-text-field
                  label="Nachname"
                  v-model="lastname"
                  type="text"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="E-Mail"
                  v-model="mail"
                  type="mail"
                  :rules="[rules.required, rules.mail]"
                ></v-text-field>
              </v-col>

              <v-col v-if="type === 'admin'" cols="12">
                <v-combobox
                  v-model="selectedCompanies"
                  :items="allCompanies"
                  item-text="name"
                  item-value="id"
                  :return-object="false"
                  :search-input.sync="search"
                  hide-selected
                  label="Du kannst suchen"
                  multiple
                  small-chips
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip v-bind="attrs" :input-value="selected" label small>
                      <span class="pr-2">
                        {{ getItemName(item, allCompanies) }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>

                <!-- <v-select
                  class="align-self-center"
                  v-model="selectedCompanies"
                  :items="allCompanies"
                  item-text="name"
                  item-value="id"
                  label="Companies"
                  multiple
                  :rules="[rules.required]"
                ></v-select> -->
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="white--text"
                  @click="validate"
                  :loading="preloader"
                  :disabled="!valid"
                >
                  {{ job === "create" ? "Erstellen" : "Aktualisieren" }}
                </v-btn>
              </v-col>
            </template>

            <template v-else>
              <v-col cols="12">
                <Patcher
                  :itemValue="data.type"
                  itemName="type"
                  itemLabel="Admin-Typ"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="select"
                  inputType=""
                  :selectOptions="typeItems"
                  @emit="data.type = $event.value"
                ></Patcher>

                <Patcher
                  v-if="data.type === 'admin'"
                  :itemValue="data.sex"
                  itemName="sex"
                  itemLabel="Anrede"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="select"
                  inputType=""
                  :selectOptions="sexItems"
                  @emit="data.sex = $event.value"
                ></Patcher>

                <Patcher
                  v-if="data.type === 'admin'"
                  :itemValue="data.firstname"
                  itemName="firstname"
                  itemLabel="Vorname"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  @emit="data.firstname = $event.value"
                ></Patcher>

                <Patcher
                  v-if="data.type === 'admin'"
                  :itemValue="data.lastname"
                  itemName="lastname"
                  itemLabel="Nachname"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  @emit="data.lastname = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.mail"
                  itemName="mail"
                  itemLabel="E-Mail"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  @emit="data.mail = $event.value"
                ></Patcher>

                <pre>{{ selectedCompanies }}</pre>
                <pre>{{ buildArrayWithNames(selectedCompanies) }}</pre>
                <Patcher
                  v-if="!preloader && data.type === 'admin'"
                  :itemValue="selectedCompanies"
                  itemName="companyIds"
                  itemLabel="Companies"
                  :route="patchRoute"
                  inputElement="multiselect"
                  inputType=""
                  selectValueKey="id"
                  selectTextKey="name"
                  :selectOptions="allCompanies"
                  :previewValue="buildArrayWithNames(selectedCompanies)"
                  @emit="selectedCompanies = $event.value"
                ></Patcher>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "EditAdmin",
  props: ["trigger", "job", "id", "itemData"],
  components: {
    Patcher,
  },
  data() {
    return {
      search: null,
      route: "admins",
      patchRoute: "admins/" + this.id,
      data: [],
      selectedCompanies: [],
      allCompanies: [],
      preloader: false,
      valid: false,
      title: this.job === "create" ? "Neuer Admin" : "Admin bearbeiten",

      type: "",
      sex: this.itemData ? this.itemData.sex : "",
      firstname: this.itemData ? this.itemData.firstname : "",
      lastname: this.itemData ? this.itemData.lastname : "",
      mail: this.itemData ? this.itemData.mail : "",

      sexItems: [
        { name: "Weiblich", value: "female" },
        { name: "Männlich", value: "male" },
        { name: "Divers", value: "diverse" },
      ],
      typeItems: [
        { name: "Admin", value: "admin" },
        { name: "FACEFORCE", value: "faceforce" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        mail: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  created: function () {
    this.getData();
    this.getAllCompanies();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.job === "update") {
          this.putData();
        } else {
          this.postData();
        }
      }
    },

    getItemName(item, items) {
      let name = "";

      // check, if item is an object
      if (typeof item === "object") {
        name = item.name;
      } else {
        // if not, get name from items
        Object.keys(items).forEach(function (k) {
          if (items[k].id === item) {
            name = items[k].name;
          }
        });
      }

      return name;
    },

    buildArrayWithNames(items) {
      let nameArray = [];

      items.forEach((item) => {
        this.allCompanies.forEach((comp) => {
          if (comp.id === item) {
            nameArray.push(comp.name);
          }
        });
      });

      return nameArray;
    },

    async getAllCompanies() {
      this.preloader = true;
      let response = await this.getRequest(`companies`);
      this.allCompanies = response.data.data;
      this.preloader = false;
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `admins/${this.id}?include=companyIds`
      );
      this.data = response.data.data;
      this.selectedCompanies = this.data.included.companyIds;
      this.getAllCompanies();
    },

    async postData() {
      let formData = new FormData();
      formData.append("type", this.type);
      formData.append("sex", this.sex);
      formData.append("firstname", this.firstname);
      formData.append("lastname", this.lastname);
      formData.append("mail", this.mail);
      formData.append("companyIds", this.selectedCompanies);

      this.preloader = true;
      let response = await this.postRequest(this.route, "", formData);
      this.preloader = false;
      let newItem = response.data.data;
      this.$emit("createdItem", newItem);

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Datensatz wurde angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },
  mixins: [apiRequest],
};
</script>
