<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="data"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :search="search"
          :preloader="preloader"
        />
        <v-btn fixed dark fab bottom right color="primary" @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <div v-if="edit">
          <EditAdmin
            :trigger="edit"
            :job="job"
            :id="id"
            :itemData="itemData"
            :key="componentKey"
            @closeModal="edit = false"
            @createdItem="itemCreated"
            @emit="emittedAction"
          />
        </div>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
import EditAdmin from "@/components/csm/admins/EditAdmin.vue";
export default {
  name: "Admins",

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
    EditAdmin,
  },

  data() {
    return {
      pageTitle: "Administratoren",
      pageDescription: "Die Admins der Firma",
      title: "",
      edit: false,
      job: null, // create/update
      id: null,
      itemData: null,
      componentKey: 0,
      search: "",
      data: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "firstname", value: "firstname" },
        { text: "lastname", value: "lastname" },
        { text: "mail", value: "mail" },
        { text: "type", value: "type", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      preloader: false,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    itemCreated() {
      this.edit = false;
      this.getData();
    },

    emittedAction(action) {
      if (action.action === "editItem") {
        this.edit = true;
        this.job = "update";
        this.id = action.itemId;
        this.itemData = action.itemData;
      } else if (action.action === "deleteItem") {
        this.id = action.itemId;
        this.deletePrompt();
      }
    },
    addItem() {
      this.componentKey += 1;
      this.id = null;
      this.edit = true;
      this.itemData = null;
      this.job = "create";
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `admins?filter[type]=admin,faceforce`
      );
      this.preloader = false;
      this.data = response.data.data;
      this.title = response.data.data[0].name;
    },

    async deleteItem() {
      this.preloader = true;
      await this.deleteRequest(`admins/${this.id}`);
      this.preloader = false;
      this.getData();
    },

    async deletePrompt() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieser Admin tatsächlich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.deleteItem();
      }
    },
  },

  mixins: [apiRequest],
};
</script>
